element.style {
}

@media screen and (min-width: 768px){}
    .footer {
        padding: 32px 0;
    }

    .footer {
        background: #000;
        padding: 17px 0 32px;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #b2b2b2;
        text-align: center;
    }

    article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
        display: block;
    }

    *, :after, :before {
        box-sizing: border-box;
    }

   
    footer {
        display: block;
    }

    body {
        font-family: Nunito Sans, Helvetica, Tahoma, Arial, serif;
        -webkit-overflow-scrolling: touch;
        background: #000;
        color: #fff;
    }

    body, html {
        font-size: 10px;
    }

    html {
        font-family: sans-serif;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
    }

    *, :after, :before {
        box-sizing: border-box;
    }

    *, :after, :before {
        box-sizing: border-box;
    }

    ::selection {
        background-color: #00ebaa;
        color: #fff;

    }
    .SvgTwiter{
        enable-background:new 0 0 24 20
    }